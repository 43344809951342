export default {
    namespaced: true,

    state: () => ({
        styles: [],
        classes: [],
    }),

    getters: {
        styles: state => {
            return state.styles.reduce((acc, value) => {
                return { ...acc, ...value };
            }, {});
        },
        classes: state => [...new Set(state.classes)],
    },

    mutations: {
        setStyle(state, [property, value]) {
            state.styles.push({ [property]: value });
        },
        removeStyle(state, property) {
            const index = state.styles
                .reverse()
                .findIndex(value =>
                    Object.prototype.hasOwnProperty.call(value, property),
                );
            if (index > -1) {
                state.styles.splice(index, 1);
            }
        },
        addClass(state, value) {
            state.classes.push(value);
        },
        removeClass(state, value) {
            const index = state.classes.indexOf(value);
            if (index > -1) {
                state.classes.splice(index, 1);
            }
        },
    },
};
